<template>
  <div>
    <b-card>
      <div class="app_title_box">
        <h4>Logs</h4>
      </div>
      <div class="search_input">
        <div class="row">
          <div class="col-md-4">
            <p>Search logs by date</p>
            <b-form-datepicker
              id="example-datepicker"
              v-model="date"
              class="mb-1"
              @input="searchLogs"
            />
          </div>
        </div>

      </div>
      <div
        class="code_view"
        :class="logs.length < 1 ? 'no-log-view' : ''"
      >
        <h2 v-if="logs.length < 1">No Logs Found</h2>
        <div class="log_item" v-else>
          <div class="log_content">
            <div
              v-for="(log, index) in logs"
              :key="index"
              class="log__data"
            >
              <span class="date_log">[<span class="log_date">{{ log.date }}</span>]</span>
              <span> -- </span>
              <span class="log_message">{{ log.message }}</span>
            </div>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BButton, BModal, VBModal, BCardText, BTable, BFormDatepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardCode from '@core/components/b-card-code'
import { notifyMe } from '@/libs/toast'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'

export default {
  components: {
    BCard,
    BButton,
    BModal,
    BCardText,
    BTable,
    BCardCode,
    BFormDatepicker,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      logs: [],
      date: new Date(),
      defaultDate: moment().format('DD-MM-Y'),
    }
  },
  mounted() {
    this.getLogs()
  },
  methods: {
    getLogs(date = this.defaultDate) {
      window.cxSocketApi.talk('get-log-details', response => {
        this.logs = response.data
      }, error => notifyMe(error.data, 'danger'), date)
    },
    searchLogs(value) {
      const date = moment(value).format('DD-MM-Y')
      this.getLogs(date)
    },
  },
}
</script>

<style>

</style>
